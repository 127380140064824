


.links-container {
  display: flex;
  flex-wrap: wrap; /* Optional: allows wrapping to multiple lines */
  gap: 10px; /* Optional: adds space between the items */
}
.upload_btn input {
  height: 171px;
  border: 1px solid;
  width: 351px;
  position: absolute;
  opacity: 0;
}

.upload_btn {
  position: relative;
  width: 6%;
}

.link-item {display: flex;align-items: center;gap: 5px;}
.head_deal {margin-top: 10px;}
h1.deal_Brand_agency {font-size: xx-large;font-weight: 600;padding-bottom:.5em;}
.head_deal ul.deal_list {display: flex;justify-content: space-between;list-style: none;width: 80%;}
.deal_list span {color: #3182ce;}
.deal_list li {display: flex;}
.detail {color: #3182ce;}
.tabs {margin-top: 30px;}
.tab_head {font-weight: 600;}
.tab_deatil .form_list {font-size: large; padding: 7px; display: grid;}
button.back_Btn { background: black;}
.tab_deatil span.detail {min-width: 150px;max-width: 250px;}
.tab_deatil .form_list input ,.tab_deatil .form_list select{min-width: 265px !important;}
.tab_deatil.edit_tab span.detail {max-width:200px;}
.column-email {
  max-height: 50px;
  overflow-y: auto;
  padding-right: 2px;
}

.form_list2{
	height: calc(100vh - 220px);
	overflow: auto;
}


.column-email::-webkit-scrollbar {
  width: 2px;
}

.column-email::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.column-email::-webkit-scrollbar-thumb {
  background: #888;
}

.column-email::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.new_deal_form .field_list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.new_deal_form .tab_deatil.edit_tab span.detail ,.new_deal_form .tab_deatil .form_list .detail{
	font-weight: 700;
	color: #333;
	font-size: 16px;
}
.new_deal_form .tab_deatil.edit_tab span {
	color: #333;
	font-size: 16px;
}
.new_deal_form .tab_deatil .form_list {
	display: inline-block;
	padding: 15px 15px 0;
  width: 100%;
}
.new_deal_form .tab_deatil .form_list span img {
	object-fit: cover;
	border-radius: 10px;
	width: 140px;
	height: 140px;
  margin: 10px 0;
}
.new_deal_form .tab_deatil .form_list button {
	font-size: 14px;
	border: 1px solid #999;
	padding: 3px 10px;
	border-radius: 28px;
}
.new_deal_form .tab_deatil .form_list .detail {
	width: 100%;
	display: block;
}
.new_deal_form .tab_deatil .edit-profile {
	border-color: #000;
	margin-top: 10px;
	padding: 20px 10px;
	width: 200px;
	text-align: center;
	height: 150px;
	max-width: 100%;
}
.new_deal_form .tab_deatil .edit-profile .css-gmuwbf {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 150px;
}
.new_deal_form .tab_deatil .edit-profile .css-gmuwbf p.chakra-text {
	font-size: 13px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.new_deal_form .field_list input,.new_deal_form .form_list textarea,.form_list input.chakra-input {
	border: 1px solid #000 !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 16px;
  max-width: 265px;
}
.new_deal_form .form_list textarea{
  resize: none;
  height: 150px;
  max-width: 100%;
}
.new_deal_form .field_list .form_list {
	width: 50%;
}
.new_deal_form .tab_deatil:first-child {
	max-width: 700px;
}
.tab_deatil .form_list input, .tab_deatil .form_list select {
	min-width: 265px !important;
}
.new_deal_form .tab_deatil .edit-profile a {
	color: #000;
}
.top_action_btn {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}
/* .cancel_btn {
	background: #e53e3e;
} */
 
.custom_meta_text {
	color: #fff !important;
}
.custom_meta {
	background: #672e8e !important;
}
.custom_meta svg {
	color: #fff;
	opacity: 1;
}
.new_deal_form .tab_deatil .form_list .custom_meta button {
	border: 1px solid #fff !important;
	opacity: 1;
}
.custom_meta svg, .custom_meta svg path {
	fill: #fff;
	opacity: 1;
	stroke: #fff;
}
.detail.meta_heading {
	margin-bottom: 10px;
}
span.custom_meta.css-5y0fvh {
    margin-top: 11px;
}

@media screen and (min-width:1199px) and (max-width:1439px) {
.chakra-tabs__tab-panels.deals_details {
    max-height: 50vh;
}
}


@media screen and (min-width:1440px) {
	.chakra-tabs__tab-panels.deals_details {
		max-height: 62vh;
	}
}
	

@media screen and (max-width:767px) {
.head_deal ul.deal_list {display: unset;list-style: none;width: 80%;}
.deal_page_container button {padding: 5px 10px;}
.css-1iykvbk {max-height: 14.6875em;}
.deal_list span {color: #3182ce;padding-bottom:10px;}
.tab_deatil {padding: 10px 0;}
.tab_deatil .form_list {flex-wrap: wrap;margin-bottom: 10px;}
.tab_deatil .form_list div {width: 100%;}
.tab_deatil .chakra-select__icon-wrapper { width: auto !important;}
.tab_deatil .chakra-input__right-element {width: auto !important; padding-right: 10px;}
/* .tab_deatil.edit_tab span.detail {max-width: inherit;width: auto;min-width: inherit;} */
.new_deal_form .field_list .form_list {
	width: 100%;
}
.all-tab .chakra-tabs__tablist {
    overflow-x: auto;
	padding-bottom: 2px;
}
.all-tab .chakra-tabs__tablist .tab_head {
    min-width: 100px!important;
}
.chakra-tabs__tab-panels {
    height: 100vh;
    overflow-y: auto;
}
.overflow_height {
    max-height: 420px;
    overflow-y: auto;
}
.overflow_box{
	overflow: auto;
	height: 100vh;
}
	.form_list2{
		height: calc(100vh - 280px) !important;
	}
}
select#creators-dropdown {
  max-width: 150px!important;
  width: unset;
}

