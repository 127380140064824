body .css-5hkd71 {
  width: 100%;
  height: calc(99vh - 180px);
}
  @media screen and (max-width : 767px){
    body .css-5hkd71 {
      width: 100%;
      height: calc(90vh - 270px);
    }
    .chakra-ui-light .css-d0yeag{
      position:fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    
    } 
    .chakra-ui-light .css-10wb7sy .css-1lekzkb {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: white;  
    }
    .chakra-ui-light .css-1rr4qq7 .css-1lekzkb{
      position: fixed;
        bottom: 0px;
        left: 0;
        width: 100%;
        background: white;
        z-index: 999;
        padding: 5px 20px;
    }
      .chakra-ui-light .css-10wb7sy .css-1lekzkb .css-193m7l6 {
      margin-right: -10px;
  }
    .chakra-ui-light .chakra-tabs .css-10wb7sy .css-r0pt5d{
      width:100%;
    }
    body.chakra-ui-light .inbox_pagination_content {
      padding-left: 0px !important;
  }
  .chakra-ui-light .inbox_pagination_button {
    padding-right: 0px !important;
}
    /* .chakra-ui-light .css-1rr4qq7 .css-8atqhb {
      height: 80vh;
  } */
  

.chakra-ui-light .css-1rr4qq7 .chakra-tabs__tab-panels .chakra-skeleton .css-r0pt5d{
  height: calc(100vh - 273px) !important;
}
.chakra-ui-light .css-k008qs .css-10wb7sy .css-5hkd71,body .here.scoting_height{
  height: calc(90vh - 275px) !important;
}
body .company-table-height, body .prospert-tabel-height, body .Influencer_height {
  height: calc(85vh - 275px);
}
.chakra-ui-light .css-k008qs .css-10wb7sy .css-5hkd71>div{
  padding-bottom: 80px !important;
}
body .Influencer_height table tbody tr:last-child td {
	/* padding-bottom: 80px !important; */
}
body .scoting_height table tbody tr:last-child td {
	/* padding-bottom: 80px !important; */
}
body .full-width-sequences>div:nth-child(2){
  padding-bottom: 200px;
}
    }
    body.chakra-ui-light .inbox_pagination_content {
      padding-left: 30px;
  }
  .chakra-ui-light .inbox_pagination_button {
    padding-right: 30px;
}

.padding-for-pagination {
  padding: 0px 20px;
}
    