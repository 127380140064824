.modal-body {
  margin: 70px 10px;
  border-radius: 10px;
  padding: 20px;
  height: 500px;
}

.ql-toolbar {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.new_template div#toolbar {
  overflow: unset;
}

.custom-quill-container {
  display: flex;
  flex-direction: column-reverse;
}
.create-new-template .ql-toolbar.ql-snow {
  position: relative;
  bottom: 0;
}
.create-new-template .quill-editor {
  min-height: 344px;
  height: 100%;
}

.quill.quill-editor {
  height: 215px;
}
.create-new-template .quill-editor .ql-toolbar {
  box-shadow: none;
}
.create-new-template .ql-container {
  margin-bottom: 5px;
}
.create-new-template .quill.quill-editor{
  display:flex;
  flex-direction:column-reverse;
}
.quill-editor .ql-toolbar {
  display: none;
  position: absolute;
  background-color: #ffffff;
  border: none;
  padding-bottom: 10px;
  bottom: 75px;
  box-shadow: 3px 1px 5px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow: inherit;
}

.quill-editor .ql-container {
  border-top: 1px solid #ccc;
  min-height: 200px;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ccc;
}

.quill-editor .ql-container {
  border-top: 1px solid #ccc;
  min-height: 200px;
}

.css-1fzly1n {
  margin-top: 65px;
  margin-left: 15px;
}

.header-linking-area {
  display: flex;
  align-items: center;
}

/* .ql-editor {
  display: flex;
} */

.hitemplate_icon {
  font-size: 24px;
  /* margin-top: 10px; */
  margin-left: 15px;
}

.ql-toolbar.ql-snow {
  display: block;
}

.edit-template-name{
  margin-bottom: 10px;
  padding-bottom: 5px;
}

@media (max-width: 768px) {
  .ql-toolbar.ql-snowP {
    display: none;
  }
  .hitemplate_icon {
    font-size: 24px;
    /* margin-top: 10px; */
    margin-left: 5px;
  }
  /* .css-yz3ute {
    position: absolute;
    top: 80px;
    width: 100%;
} */
}

/* @media screen and (max-width:430px){
  .create-new-template .quill-editor .ql-container {

    min-height: 150px;
}
} */

@media screen and (max-width:767px){
  .create_new_template_name {
    display: flex;
    flex-wrap: nowrap !important;
    margin-top: -10px;
    padding-top: 5px;
  }
  .create-new-template .quill-editor .ql-container {
    min-height: 260px;
    margin-top: 20px;
    height: 100%;
  }

  .edit-template-name{
    display: flex;
    flex-wrap: nowrap !important;
    margin-top: 0px;
    padding-top: 5px;
  }
}
@media screen and (max-width:431px) {
  .create-new-template .quill-editor .ql-container {
    min-height: 240px;
    margin-top: 20px;
    height: 100%;
  }

  .edit-template .quill.quill-editor {
    height: 235px;
    margin-top: -5px;
  }

  .edit-template-name{
    margin-top: 10px;
  }
}