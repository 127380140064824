/* Add CSS styles to the Quill editor content area */
.ql-editor {
    white-space: pre-line;
    font-size: medium;
  
  }

#toolbar{
  border : none;
}

/* Set dropdown font-families */
/* #toolbar .ql-font span[data-label="Aref Ruqaa"]::before {
  font-family: "Aref Ruqaa";
}
#toolbar .ql-font span[data-label="Mirza"]::before {
  font-family: "Mirza";
}
#toolbar .ql-font span[data-label="Roboto"]::before {
  font-family: "Roboto";
}
#toolbar .ql-font span[data-label="Halvatica"]::before {
  font-family: Helvetica;
}


.ql-font-aref{
  font-family: "Aref Ruqaa";
}
.ql-font-mirza {
  font-family: "Mirza";
}
.ql-font-times {
  font-family: 'Times New Roman', Times, serif;
}
.ql-font-arial{
  font-family: Arial ;
}
.ql-font-helvatica{
  font-family: Helvetica ;
}
.ql-font-sans-serif{
  font-family: sans-serif;
}
.ql-font-courier-new{
  font-family:'Courier New', Courier ;
}
.ql-font-monospace{
  font-family: monospace;
}
.ql-font-raleway{
  font-family: 'Raleway';
} */

.ql-font-courier-new{
  font-family: 'Courier New', Courier, monospace ;
}
