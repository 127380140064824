p.chakra-text.list_item_t.css-0 {
  font-size: larger;
}

p.chakra-text.list_item_t.css-0 {
  font-size: larger;
  font-weight: bold;
}

.time-display {
  margin-right: 10px;
  /* Pushes the time to the rightmost end */
  font-size: 0.875rem;
  /* Adjust font size as needed */
  /* color: gray; Change text color if needed */
  position: absolute;
  right: 0;
  font-weight: bold; 
}

.time-display-unread {
  margin-right: 10px;
  /* Pushes the time to the rightmost end */
  font-size: 0.875rem;
  /* Adjust font size as needed */
  /* color: gray; Change text color if needed */
  position: absolute;
  right: 0;
  /* font-weight: bold; */
}

/* .Right-next-page{
    position: absolute;
    right: 0;
  } */
.paents-iconmove {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding-right: 10px;
}

.Left-next-page,
.Right-next-page {
  cursor: pointer;
  /* Adds a pointer cursor to indicate clickability */
}

.card_model_list .chakra-card {
  width: 100%;
  max-width: 100%;
}

.card_model_list .box_img {
  width: 100%;
  border-radius: 4px 5px 0 0;
}

.card_top {
  max-height: 245px;
  width: 100%;
  padding: 0 !important;
  margin-bottom: 0;
  overflow: hidden;
}

.notice {
  padding: 0 !important;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  font-size: 10px;
}

.chakra-card.inbox_card {
  border: 1px solid #000;
  cursor: pointer;
}

.chakra-card.inbox_card hr {
  display: none;
}

.card_model_list .box_img .chakra-card__footer {
  padding: 10px 15px;
}

.chakra-card.inbox_card p {
  color: #457fb2;
  font-size: 18px;
  font-weight: bold;
}

.card_model_list .box_img img {
  width: 100%;
  max-width: 100%;
  border-radius: 0;
}

.page_box {
  overflow: auto;
  height: 90vh;
}

.left_emailside {
  width: 85%;
}

.from_span {
  width: 15%;
  min-width: 200px;
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.subject_name {
  white-space: nowrap;
  overflow: hidden;
}

.snippet_span {
  display: inline-block;
  width: 30vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.accordion_button_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.subject_strong {
  font-weight: 600;
  /* Lighter font weight, or you can use values like 300 or 100 */
  color: #444;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subject_strong-un {
  /* font-weight: 600; Lighter font weight, or you can use values like 300 or 100 */
  /* color:#444 */
  font-weight: normal;
  font-size: 14px;
  color: #666;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-divider-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between; /* Place items at opposite ends */
  align-items: center; /* Vertically align items if needed */
  width: 100%; /* Optional: Set width for container */
  padding: 10px; /* Optional: Add padding */
  box-sizing: border-box;
  /* Adjust this value to move both the button and divider down */
}

/* button {
    margin-bottom: 10px; 
  } */
.inbox_template_divider {
  /* margin : 0px 10px */
}

.chakra-form-control {
  margin: 0;
}

.label_feat,
.index_setting_popup label {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 700;
}

p.doble-text {
  font-size: 12px;
}

.back_handel {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.button-divider-container {
  padding-left: 5px;
}

.index_setting_popup .chakra-form-control {
  padding: 0;
  margin: 0 0 15px !important;
}

.index_setting_popup header {
  font-size: 30px !important;
}

.index_setting_popup p,
.index_setting_popup div {
  font-size: 16px;
}

.pop_tags {
  padding: 0 5px 3px;
  background: #dddcdc;
  border-radius: 5px;
  margin-left: 5px;
}

.template_footer .template_footer_td {
  min-width: 30px;
}

.template_footer button {
  margin-right: 15px;
}
@media (min-width: 1024px) and (max-width: 1200px){
  .from_span {
    width: 15%;
    min-width: 125px;
  }
  .snippet_span {
    width: 12vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px){
  .from_span {
    width: 12%;
    min-width: 100px;
    padding-right: 15px;
  }
  .snippet_span {
    width: 20vw;
  }
}

@media screen and (max-width:1200px) {
  .button-divider-container {
    position: unset !important;
  }

  body.chakra-ui-light .back_arrow_design .delete_archive_icons {
    right: 0 !important;
    width:20% !important;
  }

  body.chakra-ui-light .back_arrow_design .delete_archive_icons {
    margin-right: 0px;
  }
}

@media screen and (min-width: 1200px) {
  span.email_tag {
    display: flex;
    align-items: center;
  }

  .subject_name {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .email_tag {
    width: 100%;
    display: flex;
    gap: 10px;
  }
  .from_span {
    width: 15%;
    min-width: 125px;
    display: inline-block;
    max-width: 125px;
  }

  body .inbox_lists {
    grid-template-columns: unset;
    grid-column-gap: 10px;
    row-gap: 10px;
    padding: 0;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .card_model_list .chakra-card {
    max-width: 150px;
  }

  .box_img {
    max-height: 140px;
    overflow: hidden;
  }

  body .card_top {
    max-height: 145px;
    margin-bottom: 0;
  }

  body .chakra-card.inbox_card {
    margin: 0 !important;
  }

  body .inbox_lists .chakra-card__footer {
    padding: 10px;
    width: 100%;
  }

  body .inbox_lists .chakra-card__footer p {
    text-align: center;
    width: 100%;
    font-size: 14px;
  }

  .card_model_list .box_img img {
    max-height: 140px;
  }

  .page_box {
    height: 70vh;
    padding-bottom: 80px;
  }

  .left_emailside p {
    font-size: 13px !important;
    line-height: 20px;
    padding: 0 !important;
  }

  .left_emailside {
    width: 100%;
  }

  .snippet_span {
    width: 50%;
  }

  .list_row {
    width: 100%;
  }

  .subject_strong-un {
    width: 50%;
  }

  .from_span {
    width: 100%;
    display: inherit;
  }

  .right_emailside p {
    top: 7px;
    font-size: 11px;
  }

  .email_list_box li {
    padding: 15px 0;
  }

  .label_feat,
  .index_setting_popup label {
    font-size: 16px !important;
  }

  .index_setting_popup p,
  .index_setting_popup div {
    font-size: 13px;
  }

  .index_setting_popup .chakra-form-control {
    flex-wrap: wrap;
  }

  .pop_tags {
    margin-left: 0;
  }

  .chakra-ui-light .button-divider-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .delete_archive_icons svg {
    font-size: 27px;
}
body.chakra-ui-light .back_arrow_design .button-divider-container{
  display: flex !important;
  align-items:flex-start;
}
/* .email_reply_sec .chakra-collapse {
  padding-bottom: 150px;
} */
/* .email_reply_sec .chakra-collapse>div{
  padding-bottom: 150px;
} */


}

#root .css-1rr4qq7 .delete_archive_icons {
  position: relative;
  display: flex;
  right: unset !important;
  gap: 15px !important;
  margin-right: 15px;
  justify-content: end;
  margin-right: 20px !important;
}

/* .css-1lekzkb {
  padding: 0px 33px;
} */
/* .bspencilsquare_icons {
  position: absolute;
  right: 23px;
  top: 68px;
  font-size: 30px;
} */

.chakra-ui-light .css-1rr4qq7 .button-divider-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  
  .chakra-ui-light .button-divider-container .css-1d7oz5n {
  position: absolute;
  right: 48px;
  }
  .chakra-ui-light .button-divider-container .ico-hed-bt {
  order: 3;
  font-size: 30px;
  padding-right: 16px;
  }
  .chakra-ui-light .reply_modal .css-k008qs .css-34k09d {
  display: flex;
  align-items: center;
  }
  .add_search_class {
    display: flex !important;
    justify-content: center !important;
  }