/* .react-datepicker-wrapper {
    width: 100%;
} */
.z-100 {
  z-index: 100;
}

button.chakra-button.css-ez23ye {
  display: inline-block;
}
button.chakra-button.css-ez23ye + .css-0 {
  display: inline-block;
  margin-left: 15px;
}
.select-talent-name {
  webkit-padding-end: var(--chakra-space-8);
  padding-inline-end: var(--chakra-space-8);
  width: 100%;
  height: var(--input-height);
  font-size: var(--input-font-size);
  -webkit-padding-start: var(--input-padding);
  padding-inline-start: var(--input-padding);
  border-radius: var(--input-border-radius);
  min-width: 0px;
  /* outline: 2px solid transparent; */
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  padding-bottom: 1px;
  line-height: var(--chakra-lineHeights-normal);
  background: inherit;
  --select-bg: var(--chakra-colors-white);
  --input-font-size: var(--chakra-fontSizes-md);
  --input-padding: var(--chakra-space-4);
  --input-border-radius: var(--chakra-radii-md);
  --input-height: var(--chakra-sizes-10);
  border: 1px solid;
  border-color: inherit;
  padding-right: 12px !important;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.nice-select {
  margin-top: -42px;
}
.talent-dropdown {
  padding: 12px 0px;
  border: 1px solid #9f9c9c;
  background: #fff;
  position: relative;
  z-index: 9;
  box-shadow: 0px 5px 5px #a9a9a97d;
}
ul.list li {
  padding: 0px 12px;
  border-radius: 0 !important;
}
.nice-select .list li:hover {
  color: #fff;
  background: #1967d2;
}
.nice-select-search-box {
  padding: 0px 12px;
  padding-bottom: 6px;
}
.select-talent-name svg {
  width: 20px;
  height: 20px;
}
.nice-select {
  max-width: 300px !important;
}
.lab-talent {
  font-size: small;
  position: absolute;
  top: -14px;
  left: 10px;
  z-index: 1;
  background: #ffffff;
  padding-left: 1px;
  padding-right: 3px;
}
.chakra-form-control.input-password.css-1kxonj9 {
  width: 608px;
}
.chakra-form-control.input-Email.css-1kxonj9 {
  width: 608px;
}
.select-box {
  margin-top: 21px;
}
.custom-dropdown.open .select-box.remove_space .select-talent-name {
  border-color: #3182ce;
}
@media screen and (max-width: 767px) {
  .select-box.remove_space {
    margin-top: -15px;
  }
  button.chakra-button.css-ez23ye {
    font-size: 0.8rem;
  }
  button.chakra-button.css-ez23ye + .css-0 input {
    font-size: 0.8rem;
    width: 180px;
  }
  .nice-select {
    margin-top: 0px;
  }
  .acc-type {
    width: 50% !important;
  }
  .side_slection {
    width: 50% !important;
  }
}
.custom-dropdown .a1 ::placeholder {
  background-color: aqua;
}

.formBox input.chakra-input {
  max-width: 200px;
  border: 1px solid #e2e8f0 !important;
}

.select-container {
  position: relative;
  width: 300px;
}

/* .custom-dropdown {
  width: 100%;
  height: 30px;
}

.custom-dropdown:focus + .dropOption {
  display: block;
}

.dropOption {
  display: none;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
} */

.nice-select {
  height: 0px;
}

.nice-select,
.nice-select.open .list {
  width: 100%;
  width: 325px;
  border-radius: 8px;
}

.nice-select .list::-webkit-scrollbar {
  width: 0;
}

.nice-select .list {
  top: 100%;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  max-height: 210px;
  overflow-y: scroll;
}
.disabled-bold:disabled {
  font-weight: normal;
  color: black; /* Inherits the default text color */
  background-color: inherit; /* Inherits the default background color */
  opacity: 1; /* Keeps the text fully opaque */
  cursor: not-allowed;
}
.no-focus-disabled:disabled {
  font-weight: normal;
  color: inherit; /* Inherits the default text color */
  background-color: inherit; /* Inherits the default background color */
  opacity: 1; /* Keeps the text fully opaque */
  cursor: not-allowed; /* Shows a not-allowed cursor to indicate the field is disabled */
  pointer-events: none; /* Prevents focus and interactions */
}

.no-focus-disabled:disabled:focus {
  outline: none; /* Removes the focus outline */
  box-shadow: none; /* Removes any box shadow on focus */
}

.header-message {
  align-items: center;
}
.header-message {
  border-bottom: 1px solid #d9d9d9;
}
.header-message input {
  padding-left: 0;
  border: 0px;
  border-radius: 0;
  box-shadow: unset;
}
.header-message input:focus,
.border-none-two textarea:focus,
.border-none input:focus {
  box-shadow: unset;
}
.header-message label {
  margin: 0px;
}
.w-88 {
  width: 96px;
}
.border-none input {
  padding-left: 0;
  border: none;
  box-shadow: unset;
  border-radius: 0;
}
.border-none-two textarea {
  padding: 0;
  border-radius: 0;
  height: 72px;
  border: none;
  resize: none;
  box-shadow: unset;
}
.header-message input:focus {
  outline: none;
}
.border-none:focus-visible {
  z-index: 1;
  border-color: #d9d9d9;
  box-shadow: 0 0 0 0px #ffffff;
}
.header-message.spaceing {
  align-items: center;
  padding-bottom: 0;
  border: 0;
  justify-content: space-between;
}

.border-box {
  margin-top: 10px !important;
  padding: 0px 20px;
  border: 1px solid #e2e8f0;
}
.border-box-padding{
  padding: 0px 20px 20px 20px;
}
.footer-p-0 {
  padding-top: 5px;
}
.color-b * {
  opacity: 1 !important;
}
.upload_btn input {
  height: 40px;
  width: 100%;
  position: absolute;
  opacity: 0;
}
.upload_btn {
  position: relative;
  width: 100%;
  background: #3182ce;
  height: 40px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.upload_btn svg {
  color: #fff;
}

.editor_changes {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.editor_changes .ql-toolbar.ql-snow {
  width: 100%;
  position: unset;
  order: 2;
  overflow: inherit;
}

.editor_changes .ql-container.ql-snow {
  width: 100%;
  height: 170px;
  min-height: 170px;
}

@media only screen and (max-width: 767px) {
  .header-message.spaceing {
    padding-top: 5px;
  }
  .header-message {
    margin-top: 10px;
  }
  .editor_changes .ql-container.ql-snow {
    width: 100%;
    height: 130px;
    min-height: 130px;
  }
  .formBox input.chakra-input {
    max-width: 100%;
    border: 1px solid #e2e8f0 !important;
  }
}
