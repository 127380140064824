/* html{
  height:100vh !important;
} */
.Inbox-card-main {
  max-width: 235px;
}

.email_reply_sec p {
  margin: 0 !important;
  line-height: 1;
}

.email_reply_sec p br {
  display: none;
}

.email_reply_sec table,
.email_reply_sec table td,
.email_reply_sec table th {
  line-height: 1;
  margin: 0 !important;
}

p.chakra-text.all_emails_main_text.css-0 {
  color: grey;
  margin-left: 21px;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}

.chakra-accordion.acc_main_top.css-0 {
  overflow-y: auto;
  max-height: 73vh;
}

.email_inbox_outer {
  padding: 20px 30px;
  height: calc(92vh - 179px);
  overflow-y: auto;
}


.email_filter_btn button {
  border: 1px solid #000;
  color: #000;
  min-width: 90px;
  margin-inline-start: 10px !important;
}

.email_inbox_outer .email_filter_btn {
  margin: 0;
  padding: 30px 0 0;
}

.email_filter_btn button.active {
  background: #4483b3;
  color: #fff;
}

.email_inbox_outer ul li {
  position: relative;
}

.right_emailside p,
.right_emailside .message_time p {
  font-size: 14px;
  margin: 0;
}

.left_emailside p {
  font-size: 16px !important;
}

.email_list_box li {
  background: #fff;
  padding: 8px 10px;
  margin: 0;
  cursor: pointer;
}

.email_inbox_outer .chakra-text.all_emails_main_text {
  margin-bottom: 15px !important;
  font-size: 24px !important;
  color: #555 !important;
  font-weight: 600;
}

.email_inbox_outer .chakra-accordion__button {
  padding: 15px 0 15px 10px;
  background: transparent !important;
}

.back_arrow_design,
.back_arrow_design .button-divider-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* body.chakra-ui-light .back_arrow_design .button-divider-container {
  display: unset !important;
} */

.chakra-ui-light .back_arrow_design .button-divider-container svg {
  margin: 0 !important;
}

.email_inbox_outer .chakra-accordion__panel {
  padding: 0 10px 15px;
}

.email_inbox_outer .gmail_quote {
  margin: 15px 0 10px !important;
}

/* .email_inbox_outer br {
	display: none;
} */
.email_inbox_outer .chakra-accordion__button span {
  color: #151515 !important;
  font-weight: 600 !important;
}

.back_arrow_design {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .back_arrow_design svg {
  width: 24px;
  height: 24px;
} */
.delete_archive_icons svg {
  font-size: 20px;
}

.delete_archive_icons {
  gap: 16px;
  display: flex;
}

/* .all_email_search_area {
  display: flex;
  align-items: center;
  border: 1px solid #333;
  border-radius: 7px;
  padding: 0 10px;
} */
.all_email_search_area input.email_search_input {
  outline: none !important;
  border: none !important;
  font-size: 14px;
  padding: 0;
}

.all_email_search_area.not_open {
  border: none;
}

.show_all_message_in_inbox {
  /* position: relative; */
}

.chakra-input__group {
  max-width: 695px;
  margin-left: 10px;
}

.create_camp_talent .chakra-input__group {
  max-width: 695px;
  margin-left: 0px;
}

@media screen and (min-width: 1200px) {

  /* .all_email_search_area {
  float: right;
  position: absolute;
  left: 13%;
  max-width: 210px;
} */
  .all_email_search_area {
    float: right;
    position: absolute;
    left: 250px;
    right: 0;
    /* max-width: 210px; */
    z-index: 999;
    background: #fff;
    width: auto;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .chakra-input__group {
    margin-left: 0px;
  }
  .custom-url-color {
    width: 93vw !important;
    overflow: auto;
}
.chakra-accordion.acc_main_top.page_scroll_height {
  overflow-y: auto;
  max-height: 64vh !important;
}
.custom-url-color div {
  white-space: normal !important;
}

  /* body .reply_all_btns {
    position: absolute;
    bottom: -144px;
    background-color: #fff;
  }
  .reply_box_outer {
    height: 60vh;
    overflow: revert;
  } */
  body .reply_all_btns {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    justify-content: center;
    display: flex;
    padding-bottom: 20px;
    padding-top: 15px;
    border-top: 1px solid #eeeded;
  }
  .show_all_message_in_inbox {
    /* max-height: 70vh !important; */
}
  /* .email-height{
    max-height: 100% !important;
  } */
#root .css-1rr4qq7 .delete_archive_icons{
  margin-right: 6px !important;
}

  .reply_all_btns>div {
    width: calc(100%);
    max-width: 50%;
    display: flex !important;
    justify-content: center;
    margin: 0 !important;
  }

  .reply_all_btns>div button {
    width: calc(100%);
    max-width: 90%;
  }

  .reply_box_outer {
    height: 76vh;
    overflow: revert;
  }

  body .email_inbox_outer .email_list_box {
    position: relative;
    top: -42px;
    padding-bottom: 160px;
  }

  .all_email_search_area {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }

  .emailac_btn {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
  }

  .email_inbox_outer {
    padding: 10px 15px;
  }

  .email_inbox_outer .email_filter_btn {
    margin: 0;
    padding: 20px 0 0;
    column-gap: 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;

  }


  .right_emailside p,
  .right_emailside .message_time p {
    font-size: 12px;
    margin: 0;
  }

  .email_list_box li {
    padding: 15px 0;
  }

  .email_filter_btn button {
    min-width: 70px;
    font-size: 13px;
  }

  /* .email_list_box .chakra-divider {
    display: block !important;
  } */

  .left_emailside p {
    font-size: 16px !important;
    padding-right: 55px;
    line-height: 20px;
  }

  .notice {
    right: 10px !important;
  }

  .email_inbox_outer .chakra-text.all_emails_main_text {
    font-size: 14px !important;
  }

  .back_arrow_design .back_handel button {
    padding: 0;
    display: flex;
    justify-content: center;
    width: auto;
    height: 40px;
  }

  .back_arrow_design .back_handel {
    width: 80%;
    display: flex;
    align-items: center;
    padding: 0 0 10px;
  }

  .back_arrow_design .back_handel .chakra-text.all_emails_main_text {
    margin: 0 !important;
    padding-left: 5px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #555;
  }

  .card_model_list .chakra-card {
    max-width: 160px;
  }

  .left_emailside p {
    font-size: 13px !important;
  }

  .email_inbox_outer {
    margin-top: 20px;
  }

  .inbox_all_table_padding .email_inbox_outer{
    height: calc(65vh - 179px);
  }

  .inbox_other_table_padding .email_inbox_outer{
    height: calc(74vh - 179px);
  }
}

.replyBtn {
  display: flex;
  /* justify-content: right; */
  margin: 20px 0 0 0px;
}

.replyBtn button {
  border: 1px solid #000;
  color: #000;
  min-width: 90px;
  margin-inline-start: 30px !important;
  border-radius: 10px;
  padding: 6px;
  position: absolute;
  /* right: 20px; */
}

.chakra-button span {
  margin-left: 2px;
}

/* button#accordion-button-\:rr\: {
  padding: 0px 15px;
} */

@media screen and (max-width: 767px) {
  .seting_icons {
    background: #fff;
    position: relative;
    z-index: 11;
    width: 100%;
    display: inline-block;
    height: 41px;
  }

  .chakra-accordion.acc_main_top.css-0 {
    overflow-y: auto;
    max-height: 99vh;
  }

  .reply_modal .modal-body {
    padding: 0;
    margin: 0;
    height: 550px;
  }

  .reply_modal .modal-body .ql-toolbar.ql-snow {
    left: 15px;
    right: 15px;
    bottom: 60px;
  }

  .reply_modal .ql-toolbar.ql-snow .ql-formats {
    margin-right: 5px;
    font-size: 11px !important;
  }

  .reply_modal .ql-toolbar.ql-snow .ql-picker-label {
    font-size: 12px;
  }

  .reply_modal .modal-body .quill.quill-editor {
    height: 320px;
  }

  .reply_modal .modal-body div {
    margin: 0;
    padding: 0;
  }

  .reply_modal .chakra-button.css-1hvtqfr {
    font-size: 14px;
    height: 30px !important;
    min-height: inherit !important;
    padding: 0;
  }

  .emoji-icons{
    padding: 8px !important;
  }

  .reply_modal button {
    height: 30px;
  }

  .reply_modal {
    padding: 0 10px;
  }

  .reply_modal .modal-body div.chakra-stack {
    padding: 10px;
  }

    .email_composer_footer_area {
      top: 80px !important;
     
    }

  .hitemplate_icon {
    margin-top: 0px !important;
  }

  .email_composer_error {
    color: red;
    position: absolute;
    bottom: 105px !important;
  }

  .email_subpopup .back_arrow_design {
    position: relative;
    z-index: 999;
    background: #fff;
  }

  .email_subpopup .header_text_icons {
      background: #fff;
      position: relative;
      z-index: 99;
  }

  .email_subpopup .menu-full {
      background: #fff;
      position: relative;
      z-index: 99;
  }
}

.template_border_area {
  border: 1px solid rgb(18, 17, 17);
}

h2.template_header_text {
  font-size: 22px;
  margin: 10px 22px;
}

.template_footer {
  padding: 5px 20px;
}

.template_footer_td.css-1eyncsv {
  padding-inline-end: 0px
}

.template_footer_body {
  height: 100px;
  width: 100%;
  box-shadow: 2px 3px #a3d6ce;
  border: 1px solid #e0d5d5;
  overflow-y: auto;
}

.template_footer_btn {
  background: #3182ce;
  color: white;
  width: 100%;
  margin-top: 10px;
  height: 35px;
  margin-bottom: 15px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template_plus_icon {
  font-size: 18px;
  margin-top: 2px;
  margin-right: 5px;
}

.template_body_content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-size: 20px;
  font-family: "circular";
}

.template_body_edit_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  right: 30px; */
  font-size: 16px;
  margin-right: -20px;
}

.p-ico {
  text-align: center !important;
  padding-inline-start: 0px !important;
  padding-inline-end: 2px !important;
}

.template_footer_name_td {
  text-align: start !important;
  padding-left: 10px !important;
  width: calc(100%);
  max-width: 90%;
}

.keyword_input {
  border: 1px solid;
  height: 38px;
  width: 75%;
  display: flex;
  border-radius: 7px;
}

@media screen and (max-width:430px) {
  .template_footer_name_td {

    width: calc(100%);
    max-width: 75%;
  }
}

.t-_me_menu_list {
  margin-top: -25px;
  margin-left: 50px;
}

@media screen and (max-width:430px) {
  .t-_me_menu_list {
    overflow: auto;
    max-height: 200px;
    min-width: 90%;
    margin-left: 15px;
  }
}

.custom-url-color a{
  color: #0000ff !important;
  text-decoration: underline !important;
}

.detail_drop_content_area {
  top: -18px !important;
  left: 15px !important;
  width: 60vw !important;
  max-width: 900px !important;
  max-height: 40vh;
  overflow-y: auto;
}

.detail_drop_content_body {
  background-color: white;
}

.detail_drop_content_body div {
  display: flex;
  align-items: center;
  gap: 20px;
}

span.detail_drop_content_heading {
  width: 20%;
  text-align: right;
}

span.detail_drop_content_text {
  width: 80%;
  margin-left: 15px;
}

@media screen and (max-width:767px) {

  .detail_drop_content_area {
    background-color: white;
    top: -22px !important;
    left: 0px !important;
    width: 90vw !important;
    max-width: 450px !important;
    max-height: 70vh;
    margin: 10px;
  }

  .detail_drop_content_body div {
    gap: 0px;
  }
}

.t-_me_menu_list,
.t-_me_menu_list span {
  user-select: text;
  /* Allow text selection */
  -webkit-user-select: text;
  /* For WebKit-based browsers (Safari, iOS) */
  -moz-user-select: text;
  /* For Firefox */
  -ms-user-select: text;
  /* For Internet Explorer */
  pointer-events: auto;
  /* Ensure pointer events are enabled */
}

/* Disable any conflicting touch actions */
.t-_me_menu_list {
  touch-action: auto;
  /* Allow touch events to behave normally */
}

 .reply_all_btns {
  position: absolute;
  bottom: -63px;
}
/* .reply_all_btns {
  position: absolute;
  bottom: -345px;
  width: 100%;
  display: flex
  ;
  justify-content: end;
  margin: 0;
  padding: 8px 20px;
  border-top: 1px solid #ede7e7;
  }
  .reply_all_btns>div button {
    width: calc(100%);
    max-width: 100%;
    height: 40px;
}
  .reply_all_btns>div {
    width: calc(100%);
    max-width: 200px;
    display: flex;
    justify-content: center;
}  */


@media screen and (min-width: 1200px) and (max-width: 1359px) {
  .reply_all_btns {
    bottom: -40px;
    position: absolute;
}
 
.inner_links {
  height: 54vh;
  overflow-y: auto;
}
.menu-mob::-webkit-scrollbar ,.inner_links::-webkit-scrollbar{
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu-mob,.inner_links{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
}


@media screen and (min-width: 1199px) {

.reply_all_btns {
  position: fixed;
  bottom: 0;
  padding: 5px 10px;
  margin-left: 2px;
}

}