body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button.chakra-button.css-1knmlcm {
  margin-right: 20px;
}
a.active {
  color: #00AA45;
  font-weight: 500;
}

.active_icon {
  border-radius: 3px;
  padding: 3px;
  background-color: #00AA45;
  color: #fff;
}
.rbc-toolbar-label {
  margin: 0px !important;
}
.inactive_icon {
  border-radius: 3px;
  padding: 3px;
  border: 1px solid #E2E8F0;
  color: #00AA45;
}

.collapsed_inactive_icon{
  border-radius: 3px;
  padding: 3px;
  border: 1px solid #E2E8F0;
  color: #000000;
  background-color: #E2E8F0;
}

/*========================================================*/

.css-8fl3m1 {
  color: black !important;
  } 
  
  .css-xumdn4 {
    white-space: pre !important;
  }
  .css-r6z5ec {
    z-index: 11;
}

  .css-b2nqq3 {
  background: steelblue !important;
  }
   
  a.active {
  color: steelblue !important;
  }
  
  .active_icon {
  background-color: steelblue !important;
  }
  element.style {
    text-align: right;
    margin: 0px  !important;
 }
  .inactive_icon {
  color: steelblue !important;
  }
  .css-lvpttx {
  color: steelblue !important;
  }
  .css-ftaox9 {
  background: steelblue !important;
  }
  .css-j9290n {
  border-top: 3px solid steelblue !important;
  }
  .css-1j4hqjv:not([data-loaded]) {
  --avatar-bg: steelblue !important;
  }
  .css-k44g9z {
    margin: 0px !important;
}
.css-1eg3q0e {
  border-color: steelblue !important;
}
.css-moltat:disabled, .css-moltat[disabled] {
  display: none;
}
.css-19o24vx {
  display: none !important;
}
.css-x9dvyy {
  font-weight: 700;
}
.css-111hx8g {
  background: linear-gradient(to right, #4682B4, #6B9BC3, #89AFCF) !important;
}
.css-ntbx6p {
  background-image: linear-gradient(to right, #4682B4, #6B9BC3, #89AFCF) !important;
}

.big-calendar-container {
  position: absolute;
  top: 20px;
  right: 10%;
  width: 35%;
}
.deal_list.tab_deatil li {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.deal_list.tab_deatil li input {
	border: 1px solid #ddd !important;
}
.cal_color {
	fill: #4682b4;
}
/*========================================================*/
@media only screen and (min-width: 1200px) {
html.main_html_Parent{
  overflow: hidden;
}
}



@media only screen and (min-width: 768px) and (max-width: 1199px) {
body  .width-40 {
    width: 35%;
}
body  .width-60 {
  width: 65%;
}
body .css-18d0dhu {
  width: auto;
 }
 body .css-csnogw {
  flex-wrap: wrap;
}
.chakra-stack.css-ztosq6 {
  margin-top: 14px;
}
.css-15dmip1 {
  font-size: 14px;
  margin-bottom: var(--chakra-space-1);
  box-shadow: var(--chakra-shadows-xs);
  height: calc(94vh - 250px);
  border-radius: var(--chakra-radii-md);
  position: relative;
}
}

@media only screen and (max-width: 767px) {
  .talent_outer .chakra-input__group {
    margin: 0 !important;
}

  .big-calendar-container {
    top: 20%;
    right: 0%;
    width: 100%;
    padding: 0 20px;
}
.calender-container {
  padding: 10px;
}
.rbc-toolbar-label {
  margin: 0 0px;
  font-size: 12px !important;
  line-height: 25px;
  padding: 0px !important;
  vertical-align: middle;
}

.rbc-month-view {
  display: inherit !important;
}
.calender-container {
  height: 415px !important;
  overflow-x: scroll;
}
.rbc-toolbar {
display: grid !important;
grid-template-columns: 1fr 1fr 1fr !important;
padding: 0px !important;
}
.rbc-btn-group button {
  margin: 0px !important;
}
  .mobile_height {
    height: calc(92vh - 244px) !important;
  }
  .mobile_height>div{
    padding-bottom:80px;
  }
  .chakra-card {
    width: 90%;
  }
  .chakra-card input {
    width: 100%;
  } 
  .css-k008qs {
    width: 100%;
    flex-wrap: wrap;
    /* padding-bottom: 50px; */
}
.css-k008qs .css-1rr4qq7 {
  padding-bottom: 90px;
  height: 80vh;
}

.menu-left span {
  height: auto !important;
  width: 60px;
}

p.log-text {
  display: none;
}
.mob-none{
  display: none;
}
.menu-mob {
  justify-content: space-between;
  display: flex;
  width: 100%!important;
  align-items: center;
  z-index: 999;
}
h2.chakra-heading {
  font-size: 16px;
  margin-top: 20px;
}
.menu-left {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  gap: 8px;
  flex-direction: row !important;
  align-items: flex-start !important;
  width: calc(100% - 64px);
}
.css-18iel8m {
  margin: 0;
}
.menu-mobile {
  top: 80px;
  background: #fff;
  padding: 0px 20px;
  z-index: 9;
  right: 0;
  left: 0;
  position: absolute;
}
.menu-mobile a:first-child {
  display: block;
  margin-top: 20px !important;
}
.menu-mobile a:last-child {
  display: block;
  margin-bottom: 20px !important;
}
.css-18iel8m {
  margin: 0 !important;
}
.menu-mobile a {
  display: block;
  margin-bottom: 10px;
}
.css-h8e6jc {
  margin: 0px !important;
}
.mob-dashboard {
  overflow: hidden !important;
  width: 100% !important;
}
.flex-none {
  display: block !important;
}
.full-width {
  width: 100% !important;
}
body .css-a5stnf {
  height: calc(90vh - 170px);
}
body .css-qvs577 {
  padding: 60px 80px;
}
body .css-lb4083 {
  width: 100%;
}
body .css-5hkd71 {
  width: 100%;
  height: calc(90vh - 270px);
}
/* .css-13x6r4f {
  flex-wrap: wrap;
} */
/* button.chakra-button.css-hipoo1 {
  margin-top: 10px;
} */
body .full-width-sequences {
  flex-wrap: wrap;
}
body .css-1k9efnl {
  flex-wrap: wrap !important;
  gap: 18px;
}
body .css-1rfml5a {
  flex-wrap: wrap;
}
body .full-width-sequences {
  flex-wrap: wrap;
  height: 100%;
}
.chakra-card {
  margin: 0px auto !important;
  margin-top: 20px !important;
}
body .width-40 {
  margin-bottom: 40px;
}
body .css-1pjimdy {
  flex-wrap: wrap;
  justify-content: center;
}
body .css-bhxj9s {
  padding-top: 20px;
}

body .css-70qvj9 {
  flex-wrap: wrap;
}
body .css-csnogw {
  flex-wrap: wrap;
}
body .chakra-stack.css-ztosq6 {
  margin-top: 14px;
}
body .css-1rfml5a {
  flex-wrap: wrap;
}
body .css-yo8fgf {
  width: 100%;
}
body .css-1bsfxsg {
  width: 100%;
  height: calc(100% - 184px);
  padding-left: 0;
  padding-right: 0;
}
body .css-f7p97b {
  flex-wrap: wrap;
  height: auto;
  
}
body .chakra-card__body.css-1idwstw {
  padding: 15px;
}
body p.chakra-text.css-16vuq4 {
  padding-top: 2px;
}
.css-18m1uh3 .chakra-card {
  margin: 10px 0px 0px 0px !important;
}
body .css-yo8fgf {
  max-height: 184px;
  width: 100%;
}
body .css-yo8fgf {
  max-height: 184px;
  width: 100%;
}
.css-1rfml5a input {
  width: 100%;
}


body .css-16u4syx {
  width: 100%;
}
body .css-1cq8nmt {
  flex-wrap: wrap;
}
body .css-19lyzd3 {
  flex-wrap: wrap;
}

body .css-16u4syx {
  max-width: 100%;
}
body .css-1efleuy {
  -webkit-align-items: baseline;
  align-items: baseline;

}

body .css-1atn8kc {
  width: 100%;
}
body .css-1mi7gtg{
  height: fit-content;
}
.body .css-wl0d9u {
  height: fit-content;
}
body .css-wl0d9u {
  height: fit-content;
}
.chakra-portal .chakra-modal__body {
  max-height: 70vh;
  overflow-y: auto;
}

.chakra-portal .chakra-modal__body.scout-list-table {
  max-height: 84vh ; 
}

.chakra-modal__body.companyModal_body {
  max-height: 65vh;
  overflow-y: auto;
}

.css-u86rt7 {
  width: 100% !important;
}
body .css-1dehdra {
  width: 100%;
}
body  .css-1tq00ko {
  font-size: 20px;
}
.css-13xcx26 {
  overflow: auto;
  position: relative;
  height: 79vh;
  }
  .chakra-skeleton.css-cdkrf0 {
  height: 70vh;
  }

  .seq_details .chakra-skeleton.css-cdkrf0 {
    height: 30vh;
    }
  
  .sequences-section .chakra-skeleton{
    height: auto;
  }
  .sequences-skeleton-text .chakra-skeleton{
    height: 100%;
  }
  .css-18454lh {
    box-shadow: none!important;
    background: none!important;
}
.chakra-input__group {
  margin: 20px 0!important;
}

.camp_table_padding .camp_page_cont{
  padding: 0 15px 0
}

.alldeal_tab_padding .all_deal_page_cont{
  padding: 0 15px 0
}

.comp_table_padding .comp_page_cont{
  padding: 0 15px 0
}

.deals_table_padding .deals_page_cont{
  padding: 0 15px 0
}

.infl_deals_table_padding .infl_deals_page_cont{
  padding: 0 15px 0
}

.infl_table_padding .infl_page_cont{
  padding: 0 15px 0
}

.past_prev_table_padding .past_prev_page_cont{
  padding: 0 15px 0
}

.prospect_table_padding .prospect_page_cont {
  padding: 0 15px 0
}

.user_table_padding .user_page_cont{
  padding: 0 15px 0
}

.analytics_table_padding .analytics_page_cont{
  padding: 0 15px 0
}

.all_deals_table_padding .all_deals_page_cont{
  padding: 0 15px 0
}

.inbox_all_table_padding .inbox_all_page_cont{
  padding: 0 15px 0
}
.inbox_other_table_padding .inbox_all_page_cont{
  padding: 0 15px 0
}

}

  @media screen and (min-device-width: 1200px) and (max-device-width: 1460px) { 
    .big-calendar-container {
      right: 14%;
  }
}
 