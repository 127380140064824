.headingss p {
    text-align: center;
    font-size: 30px;
    color: #fff;
    font-weight: 700;
}

.bg-outer {
    /* background-image: url(./Dashimage.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-outer .chakra-card.bg_styleone {
    background-image: url(./background1.webp) !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    padding: 10px 0;
}

.card_designouter.board_sec.commision_goals .carddesignsec {
    background-image: url(./background10.webp) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
}

.bg-outer .thiredsection .chakra-card.carddesignsec {
    background-image: url(./background8.webp) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.bg-outer .fourthsection .chakra-card.carddesignsec {
    background-image: url(./background5.webp) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
}

.bg-outer .sectionfive .chakra-card.carddesignsec {
    background-image: url(./background9.webp) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top !important;
}

.carddesignsec {
    position: relative;
}

.carddesignsec::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.7;
    border-radius: 10px;
}

.board_sec.fourthsection p.whitelable {
    font-weight: 600;
    padding: 15px 20px 0;
    position: relative;
    z-index: 1;
}

.bg-outer .chakra-card__header {
    position: relative;
    z-index: 1;
}

.bg-outer .chakra-card {
    background: transparent;
}

.white-sec {
    gap: 10px;
    align-items: baseline;
    padding: 40px 0 20px;
    background: #fff;
}

.white-sec h1 {
    text-align: center;
    font-size: 30px;
    color: #4682b4;
    font-weight: 700;
}

.bg-outer .chakra-card {
    display: flex;
}

.bg-outer .chakra-card {
    background: transparent;
}

.dash_line {
    font-size: 28px;
    margin-right: 5px;
    color: #fff;
    text-align: center;
}

.bg-outer .chakra-card__body {
    flex: none;
}

.bg-outer .col-result .chakra-card .chakra-text {
    font-weight: 700;
    color: #fff;
}

.bg-outer .chakra-card {
    background: #fff0 !important;
}

.flex-space {
    justify-content: space-between;
    width: 100%;
}

.rleft-btn {
    display: flex;
    align-items: center;
    gap: 4px;
}

.col-result {
    border-radius: 8px;
    background: #fff !important;
    text-align: center !important;
    padding: 20px !important;
    height: auto !important;
    font-weight: 600;
    flex-wrap: wrap;
}

.commision_goals .col-result div {
    flex-wrap: wrap;
}

.col-result .chakra-text {
    text-align: center;
    font-size: 38px;
    color: #4682b4;
    font-weight: 700;
    width: 100%;
}

h6.red-text {
    font-size: 16px;
    text-transform: capitalize;
    color: #ff0000;
    font-weight: 700;
}

.padding-card {
    padding: 20px;
}

.widthfull {
    padding: 0px 0px 20px;
}

.bgouter {
    padding: 20px;
    height: 90vh;
    overflow-y: auto;
}

.bgouter::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bgouter {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.boxbg_box p {
    font-weight: bold !important;
    text-transform: capitalize;
}

.carddesignsec input,
.carddesignsec button {
    background: #edf2f7;
    min-width: 190px;
    height: 45px !important;
    outline: none !important;
    box-shadow: none !important;
    font-weight: 700;
    color: rgb(26, 32, 44);
    width: 100%;
    max-width: 220px;
}

.carddesignsec button {
    min-width: 112px !important;
    width: 100%;
    margin-right: 10px !important;
}

.carddesignsec button {
    min-width: 140px;
    width: 100%;
}

.input_btn_sec div,
.bg-outer .chakra-card__body div {
    justify-content: space-around;
    display: flex;
}

.right-btn {
    display: flex;
}

.right-btn .chakra-button:last-child {
    background: #4682b4;
    color: #fff;
    text-transform: capitalize;
}

.bg-outer .chakra-card__header {
    padding: 15px 10px 0;
}

.carddesignsec .chakra-card__body {
    padding-top: 0;
}

.carddesignsec .chakra-card__body.dealsbody {
    padding: 20px;
}

.input_btn_sec {
    padding: 20px 20px 0;
}

.bg-outer .chakra-card__body {
    padding: 20px !important;
    position: relative;
    z-index: 1;
}

.commision_goals .chakra-card__header {
    padding: 0 0 15px;
    position: relative;
    z-index: 1;
}

.board_heading {
    align-items: center;
}

.board_heading svg {
    width: 50px;
    height: 41px;
    color: #000;
}


.bg-outer .input_btn_sec p {
    font-weight: 600;
    color: #fff;
}

.commision_goals .chakra-card__header p {
    color: #fff;
    font-weight: 600;
}

button.active,
input.chakra-input.DateActive {
    background-color: steelblue;
    color: white;
}

button.active:hover,
input.chakra-input.DateActive:hover {
    background-color: #4682b4;
    color: white;
    cursor: default;
}

input.chakra-input {
	border: none !important;
	width: 100%;
	max-width: 695px;
	/* outline: 1px solid #000; */
}
.create_new_template_name input.chakra-input {
    border : 1px solid inherit !important;
}
.campain-regenerate input.chakra-input {
    border: 1px solid #4682b4 !important;
    max-width: 100px;
}

input.chakra-input.linkedin-prospect {
    max-width: 100% !important;
    border: 1px solid #ababab !important;
    border-radius: 10px;
    height: 45px;
}


.all_email_search_area input.chakra-input:focus-visible {
	box-shadow: 0 0 0 1px #000000 !important;
}
/* input.chakra-input {
    border: none !important;
} */

.Green-Text {
    color: green;
    width: 100%;
}

.DateActive {
    background-color: #4682b4;
    color: white;
    cursor: default;
}
p.buttton-text-remove {
    /* font-size: initial; */
    font: caption;
    font-weight: 600;
    color: #ff0000;
}
.new_deal_form .tab_deatil .form_list button.remove-button-profile {
    border: none;
}
/* input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 10px;
    color: #fff;
    background:  #4682b4;
    z-index: 1;
    pointer-events: none;
  } */

.chakra-card__header {
    position: relative;
}

.error {
    position: absolute;
    right: 0px;
    padding: 0px 8px;
    background: #fff;
    border-radius: 10px;
    color: red;
    bottom: 0;
    font-size: 10px;
}

.btn-flex {
    display: flex;
}

/* .date-filter.css-0 span.dash_line {
    display: none;
  } */
.filter-main-cl .current-year-filter.css-0 {
    display: flex;
}

.carddesignsec button {
    min-width: 80px !important;
    font-size: 11px !important;
    width: 100% !important;
}
/* new css */
.filter-main-cl svg {
    color: #fff;
}

/* .filter-main-cl {
    justify-content: end !important;
    width: 100% !important;
    padding-right: 15px;
} */

button.left-popup-closebutton.css-1o8qips {
    width: 24px !important;
    max-width: 24px !important;
    min-width: 24px !important;
    height: auto !important;
    margin: 0 !important;
}

button.left-popup-closebutton.css-1o8qips svg {
    color: #000;
}
.bg-outer .chakra-card__header {
    z-index: 9;
}
.chakra-popover__body {
    background: #4682b4;
}
  .filter-main-cl.info_flex {
	justify-content: flex-end;
	width: 100% !important;
	padding-right: 15px;
}
.filter-main-cl.snapshot_flex {
    justify-content: flex-start;
    width: 100% !important;
    padding-left: 15px;
  }
/* .chakra-select__wrapper select.chakra-select {
    border: none;
}
.chakra-select__wrapper select.chakra-select:focus-visible {
    box-shadow: none;
} */
/* new css end */

@media screen and (max-width: 767px) {
    .carddesignsec.carddesignsec_newone .col-result {
        position: relative;
        top: -100px;
    }
    .bg-outer .input_btn_sec .filter-main-cl.info_flex .chakra-popover__popper {
        transform: unset !important;
        top: 0px !important;
        position: relative !important;
        left: 10px !important;
    }
    .filter-main-cl.info_flex svg {
        margin: 0 !important;
    }
    .bg-outer .input_btn_sec .filter-main-cl.info_flex {
        justify-content: flex-end !important;
        padding-right: 0;
    }
    .bg-outer .input_btn_sec .filter-main-cl.info_flex .chakra-popover__arrow-positioner .chakra-popover__arrow {
        display: none;
    }
    .bg-outer .input_btn_sec div,
    .bg-outer .chakra-card__body div {
        flex-wrap: wrap !important;
        justify-content: flex-start;
        gap: 9px;
        justify-content: center !important;
        width: 100% !important;
    }

    body {
        overflow: hidden;
    }

    .col-result.css-1sp1oc3 {
        margin: 0px;
    }

    .bgouter {
        height: 80vh !important;
    }

    .carddesignsec button {
        min-width: 80px !important;
        font-size: 11px !important;
        width: 100% !important;
        padding: 0px !important;
        width: auto;
        min-width: inherit;
    }

    .bg-outer .chakra-card {
        background: #fff0 !important;
        padding: 10px !important;
        margin: 0 !important;
        flex-wrap: wrap !important;
        width: 100%;
    }

    .commision_goals .chakra-card__header div {
        flex-wrap: wrap !important;
        justify-content: flex-start;
    }

    .commision_goals .chakra-card__header div .rleft-btn {
        display: flex;

    }

    .commision_goals .chakra-card__header div .right-btn {
        display: grid;
        margin-top: 3px;
        gap: 14px;
        grid-template-columns: 1fr 1fr;
    }

    .commision_goals .chakra-card__header p {
        width: 100%;
        margin: 0 0 10px;
    }

    h6.red-text {
        text-align: center;
        width: 100%;
    }

    .bg-outer .input_btn_sec p {
        width: 100%;
    }

    .bg-outer .input_btn_sec p {
        font-weight: 600;
        color: #fff;
    }

    .bg-outer .board_sec .chakra-card__body {
        padding: 0px !important;
        margin-top: 20px;
    }

    .commision_goals .col-result div {
        padding: 0 0 10px !important;
    }

    .error.css-0 {
        position: absolute;
        right: 0px;
        padding: 0px 8px;
        background: #fff;
        border-radius: 10px;
        color: red;
        bottom: -17px;
        font-size: 10px;
    }

    select#creators-dropdown {
        max-width: 150px !important;
        width: unset;
    }

    .current-year-filter.css-0 {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0px !important;
    }

    .date-filter.css-0 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2px;
    }

    .filter-main-cl.css-0 {
        width: 95% !important;
    }

    .rleft-btn-date.css-0 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0px !important;
    }

    .date-fliter-r.css-0 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .rleft-btn p {
        padding-left: 38px;
    }

    .rleft-btn {
        justify-content: center !important;
    }

    .DateActive {
        width: 125px !important;
        font-size: 11px !important;
        min-width: 90px !important;
    }

    .date-fliter-r .css-0.dash_line {
        text-align: center;
    }

    .css-1rr4qq7 {
        width: 100%;
    }

    .btn-flex button.chakra-button {
        margin-bottom: 10px;
    }

    .current-year-filter button.chakra-button.inactive.css-1wtemij {
        min-width: 105px !important;
    }

    .date-filter.css-0 input.chakra-input.DateInactive.css-1cjy4zv {
        min-width: 100px;
    }

    .bg-outer .chakra-card__header {
        padding: 15px 10px;
        width: 100%;
        max-width: 100%;
    }

    .rleft-btn-date.css-0 button.chakra-button.inactive.css-ez23ye {
        min-width: 110px !important;
        gap: 5px !important;
    }

    .date-fliter-r.css-0 input.chakra-input.DateInactive.css-1cjy4zv {
        min-width: 100px;
    }

    .box-grid .chakra-card__body {
        width: 100%;
        max-width: 300px;
        margin: 0px auto;
    }

    .col-result.css-1sp1oc3 {
        width: 100%;
        max-width: 300px;
        margin: 0px auto;
    }

    .btn-flex.css-0 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 14px;
    }

    .btn-flex.css-0 button {
        width: 100%;
        min-width: 80px !important;
    }

    .right-btn.css-0 input {
        width: 100%;
        min-width: 120px !important;
    }

    .sectionfive .col-result.css-1sp1oc3 {
        margin: 0px auto;
        width: 100%;
    }
}

@media screen and (min-device-width: 360px) and (max-device-width: 450px) {

    /* STYLES HERE */
    .date-filter.css-0 input.chakra-input.DateInactive.css-1cjy4zv {
        min-width: 90px;
        width: 100%;
        max-width: 136px;
        font-size: 12px;
    }

    .current-year-filter.css-0 {
        gap: 10px !important;
    }

    .current-year-filter button.chakra-button.inactive.css-1wtemij {
        min-width: 87px !important;
        max-width: 185px !important;
        width: 100%;
        font-size: 12px !important;
    }

    .current-year-filter button.chakra-button.inactive.css-1wtemij {
        min-width: 87px !important;
        width: 100%;
        font-size: 12px !important;
    }

    .rleft-btn-date .css-0button.chakra-button.inactive.css-ez23ye {
        width: 90px !important;
        font-size: 12px !important;
        padding: 0px;
    }


    .rleft-btn-date.css-0 {
        gap: 10px !important;
    }

    .date-fliter-r.css-0 input.chakra-input.DateInactive.css-1cjy4zv {
        min-width: 90px;
        max-width: 136px;
        font-size: 12px;
    }

    .rleft-btn-date.css-0 button.chakra-button.inactive.css-ez23ye {
        min-width: 87px !important;
        width: 100%;
        font-size: 12px !important;
        padding: 0px;
    }

    .rleft-btn p {
        padding-left: 0px;
    }

    .btn-flex.css-0 button {
        min-width: 73px !important;
    }

    .col-result.css-1sp1oc3 {
        margin: 0px;
    }
}

@media screen and (min-width: 360px) {
    .current-year-filter button.chakra-button.inactive.css-1wtemij {
        min-width: 80px !important;
        width: 100%;
        font-size: 11px !important;
    }

    .date-filter.css-0 input.chakra-input.DateInactive.css-1cjy4zv {
        max-width: 125px;
        font-size: 11px;
    }

    .date-fliter-r.css-0 input.chakra-input.DateInactive.css-1cjy4zv {
        /* min-width: 90px; */
        max-width: 125px;
        font-size: 12px;
    }

    .rleft-btn-date.css-0 button.chakra-button.inactive.css-ez23ye {
        min-width: 80px !important;
        font-size: 11px !important;
    }

    .col-result.css-1sp1oc3 {
        margin-left: 2px;
    }

    /* .snap-list .col-result.css-1sp1oc3 {
        margin-left: -9px;
    } */
    /* .sectionfive .col-result.css-1sp1oc3 {
        margin-left: 0px;
    } */
}


@media screen and (min-device-width: 412px) and (max-device-width: 430px) {
    .date-filter.css-0 input.chakra-input.DateInactive.css-1cjy4zv {
        max-width: 153px;
    }

    .current-year-filter button.chakra-button.inactive.css-1wtemij {
        min-width: 95px !important;
    }

    .rleft-btn-date.css-0 button.chakra-button.inactive.css-ez23ye {
        min-width: 95px !important;
    }

    .date-fliter-r.css-0 input.chakra-input.DateInactive.css-1cjy4zv {
        max-width: 153px;
    }

}
@media screen and (max-width:765px){
    .bg-outer{
        padding-bottom: 90px !important;
    }
}

