span.number-icon {
    position: absolute;
    top: -6px;
    right: -11px;
    height: 23px;
    width: 26px;
    background-color: rgb(248, 9, 9);
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: #fff
}

.notification-numbericon {
    position: relative;
}


.noti-icon {
    position: absolute !important;
    right: -20px;
    top: 38px;
    background-color: white !important;
    border: 2px solid #6aaedd !important;
    outline: none !important;
    min-width: 500px;
    z-index: 9999;
  }

.noti-body {
    background-color: #fff !important;
    overflow-y: auto;
    height: 338px;
    /* margin-top: 20px; */
    color: #454545;
    padding: 10px;
}

.noti-arrow {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 6px;
    font-size: 22px;
    margin-top: -16px;
}

.noti-header {
    padding-top:10px;
    text-align: center;
    font-size: 24px;
    color: #6aaedd;
    font-weight: 700;
    background: white;
}

.noti-item {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 0px;
}

.noti-item button {
    /* padding-left: 15px; */
    font-size: 10px;
}

.noti-main {
    cursor: pointer;
}

.Calender-main-cursor {
    cursor: pointer;
}

.notification-numbericon .noti-icon {
    padding-top: 20px;
}

.notification-container {
    position: relative;
  }

  .noti-close-button {
    position: absolute;
    top: 8px;
    right: 20px;
    background: none;
    border: none;
    font-size: 26px;
    cursor: pointer;
  }

@media only screen and (max-width: 767px) {
    .notification-numbericon .noti-icon {
        left: auto;
        right: -50px;
        min-width: 340px;
        z-index: 9999;
    }

    .noti-body {

        max-height: 450px;
    }

    .chakra-ui-light .general-box-class {
        position: relative !important;
        bottom: 0 !important;
        width: 100% !important;
    }
}
.bspencilsquare_icons {
    font-size: 30px;
    margin-right: 20px;

}
.trash_pen_container{
    display: flex;
    align-items: center;
    gap: 5px;
}

.all_email_trash_icons{
    padding: 0px 6px !important;
}



@media screen and (max-width : 767px) {
    .chakra-ui-light .css-1rr4qq7 .button-divider-container .css-1j6niuy {
        top: 149px !important;
    }
    .bspencilsquare_icons {
        font-size: 30px;
        margin-right: 20px;
    }
    .noti-item button {
        padding: 0 2.5px;
        font-size: 11px;
    }
    .noti-item chakra-text {
        padding-left: 5px;
    }
    .noti-item .css-4abrhy{
        width: 1rem;
    }
}

.header_text_icons{
    padding : 10px 20px;
}
@media screen and (max-width:430px){
    .header_text_icons{
        padding : 10px 15px !important;
    }
}