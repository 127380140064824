.calendar-container {
    background-color: #ffffff; /* White background for the entire calendar component */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .rbc-toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background-color: #ffffff; /* White background for the toolbar */
    padding: 10px;
    border-radius: 8px;
    flex-wrap: nowrap;
    font-size: 16px;
  }
  
  .rbc-toolbar-label {
    font-size: 1rem;
    margin: 0 15px;
    padding: 10px;
  }
  
  .rbc-btn-group {
    display: flex;
    align-items: center;
  }
  
  .rbc-btn-group button {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0 5px;
  }
  
  .rbc-btn-group button:hover {
    color: #007bff;
  }
  
  .rbc-month-view {
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff; /* White background color */
  }
  
  .rbc-date-cell {
    position: relative;
    text-align: center;
    height: 50px;
    padding: 5px;
    cursor: pointer; /* Add pointer cursor */
  }
  
  .rbc-date-cell span {
    display: block;
    margin-bottom: 5px;
  }
  
  .event-marker {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 1px;
  }
  
  .rbc-date-cell:hover {
    background-color: #f5f5f5;
  }
  
  .rbc-off-range-bg {
    background: #f9f9f9;
  }
  
  .rbc-today {
    background: #eaf6ff;
    border: 1px solid #007bff;
  }
  
  .rbc-month-row {
    border-bottom: 1px solid #ddd;
  }
  
  .rbc-header {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    background: #f8f8f8;
  }
  
  .events-list {
    margin-top: 20px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 150px; /* Add max height for scrollbar */
    overflow-y: auto; /* Add scrollbar for overflow */
  }
  
  .events-list h3 {
    margin-top: 0;
    font-size: 1.2em;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
  }
  
  .events-list ul {
    list-style: none;
    padding: 0;
  }
  
  .events-list ul li {
    padding: 5px 0;
    font-size: 1em;
    display: flex;
    align-items: center;
  }
  
  .events-list ul li::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }

/* Legend.css */
.legend {
  margin-top: 20px;
}

.legend ul {
  list-style: none;
  padding: 0;
}

.legend li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend .dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.legend .dot.red {
  background-color: red;
}

.legend .dot.blue {
  background-color: blue;
}

.legend .dot.green {
  background-color: green;
}
.rbc-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background-color: #ffffff; /* White background for the toolbar */
  padding: 10px;
  border-radius: 8px;
}

.rbc-toolbar-label {
  font-size: 1rem;
  margin: 0 15px;
  padding: 10px;
}

.rbc-btn-group {
  display: flex;
  align-items: center;
}

.rbc-btn-group button {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin: 0 5px;
}

.rbc-btn-group button:hover {
  color: #007bff;
}

.calender-container {
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  position: relative;
  border: 1px solid #000;
  z-index: 999;
}
.legend ul {
  gap: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.legend li {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.legend .dot {
  border-radius: 0;

}
.events-list {
  margin-top: 20px;
  padding: 0;
  background: #ffffff;
  border: 0 !important;
}
.rbc-month-view {
  border: 0 !important;
  border-radius: 4px;
  display: block;
  background: transparent;
}
.rbc-header {
  text-transform: uppercase;
  padding: 10px;
  font-weight: 500;
  background: #ffffff;
  border: 0 !important;
}
.rbc-month-row {
  background: #fff;
  border-bottom:0 !important;
}
.rbc-date-cell {
  border: 0px !important;
  background: #fff;
}
.rbc-row {
  border: 0px !important;
  background: #fff;
}
.rbc-row-segment {
  justify-content: center !important;
  display: flex;
}
.rbc-month-row + .rbc-month-row {
  border: 0;
}
span.event-markerblue {
  width: 5px !important;
  background:blue;
  height: 5px;
  margin: 0px 2px !important;
 
}
span.event-markerred {
  margin: 0px 2px !important;
  width: 5px !important;
  height: 5px;
  background: red;
}
span.event-markergreen {
  margin: 0px 2px !important;
  width: 5px !important;
  height: 5px;
  background: green;
}
.rbc-date-cell span {
  width: 100%;
}
.rbc-date-cell {
  flex-wrap: wrap;
  justify-content: center;
  border: 0px !important;
  background: #fff;
  display: flex;
}
.events-list h3 {
  margin-top: 0;
  font-size: 1.2em;
  border: 0px !important;
}
.events-list ul li::before {
  border-radius: 0;
}
.events-list ul li.eventslist-blue::before{
  background: blue;
}
.events-list ul li.eventslist-green::before{
  background: green;
}
.events-list ul li.eventslist-red::before{
  background: rgb(255, 0, 0);
}
.close-c svg {
  height: 26px;
  width: 26px;
}
.close-c {
  top: 10px;
  position: absolute;
  right: 15px;
}