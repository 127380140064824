.email_composer_custom_quill .ql-toolbar {
  position: absolute;
  bottom: 68px !important;
}

.modal-email-composer {
  position: fixed;
  bottom: 0;
  right: 10px;
  width: calc(100%);
  max-width: 750px;
  z-index: 8;
}

.email_composer_custom_quill .quill-editor {
  height: 200px;
}

.modal-email-composer p.chakra-text {
  display: flex;
  align-items: center;
}

.email_composer_headers {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0px;
  padding: 10px 20px;
}

.modal-email-composer .chakra-stack {
  margin-top: 30px;
}

.new_email_composer_footer_area .css-0 {
  margin-top: 0;
}

.new_email_composer_footer_area {
  display: flex;
  align-items: center;
  /* margin-top: 68px; */
}

.bcc_button {
  margin-left: 10px;
}

.bcc_button:hover {
  text-decoration: underline;
}

.bcc_button:hover {
  text-decoration: underline;
}

.new_email_composer_footer_area .hitemplate_icon {
  margin-top: 0;
}

.email_composer_text_to input {
  width: 100%;
}

.email_composer_del_icon {
  position: absolute;
  right: 25px;
}

@media (min-width: 1024px) and (max-width: 1920px) {
  .email_composer_del_icon {
    font-size: 24px;
    right: 25px;
    /* position: absolute; */
    padding: 0;
    height: 40px;
    width: 40px;
    /* padding: 6px; */
    border-radius: 30px;
    cursor: pointer;
  }

  .email_composer_del_icon:hover {
    background: #eee;
    cursor: pointer;
  }
}

/* .modal-email-composer .chakra-stack {
  height: 100%;
  margin-top: 30px;
} */
@media screen and (max-width:767px) {
  .email_composer_custom_quill .quill-editor {
    height: unset;
  }

  .email_composer_del_icon {
    /* right: 18px !important; */
    right: 0px !important;
    position: relative;
  }
  .email_composer_text_editor{
    /* overflow: auto; */
    max-height: calc(100vh - 250px);
  }

  .modal-email-composer {
    position: fixed;
    top: 0px;
    right: 0;
    width: calc(100%);
    max-width: 750px;
    height: 100%;
    z-index: 999;
  }

  .email_composer_text_cc input {
    width: 90%;
  }

  /* .modal-email-composer .chakra-stack {
  height: 100%;
} */
  .email_composer_custom_quill .quill-editor {
    height: unset;
    position: relative;
  }

  .email_composer_custom_quill .ql-toolbar {
    position: absolute;
    bottom: -80px !important;
    /* height: 37px; */
    overflow: auto;
  }

  .new_email_composer_footer_area {
    position: relative !important;
    top: 0 !important;
    margin-top: 95px !important;
  }
  .ql-editor {
    white-space: pre-line;
    font-size: medium;
    overflow-Y: auto;
    height: 200px;
  }

  .create-new-template .ql-editor {
    white-space: pre-line;
    font-size: medium;
    overflow-Y: auto;
    height: 98%;
  }


  .quill-editor-reply .ql-editor{
    white-space: pre-line;
    font-size: medium;
    overflow-Y: auto;
    height: 100%;
  }

  .quill-editor-deals .ql-editor{
    height: 129px !important;
  }
  .modal-email-composer .email_composer_text_to {
    flex-wrap: nowrap;
  }

  .email_composer_text_bcc {
    display: flex;
    flex-wrap: nowrap;
  }

  .email_composer_text_bcc input {
    margin-left: 5px;
  }
  .emoji-icon-container{
    display: none !important;
  }
}

@media (min-width:375px) and (max-width:430px) {
  .email_composer_custom_quill .ql-toolbar {
    position: absolute;
    bottom: -93px !important;
    overflow: auto;
    height: unset !important;
  }

  .new_email_composer_footer_area {
    position: relative !important;
    top: 0 !important;
    /* margin-top: 110px; */
  }

  .email_composer_custom_quill .ql-toolbar {
    position: absolute;
    /* bottom: -90px !important; */
    overflow: auto;
    height: unset !important;
  }
}