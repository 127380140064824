.allReply_modal-body .email_composer_footer_area {
    position: relative;
    top: 0;
}
.all_email_reply_cc_input>p {
    display: flex;
    align-items: center;
}
.all_email_reply_bcc_input p {
    display: flex;
    align-items: center;
    margin-right:5px;
}
@media screen and (max-width:767px){

    .all_email_reply_cc_input input {
        width: 90%;
     }
    .all_email_reply_bcc_input input {
       width: 90%;
    }

}
@media screen and (max-width:900px){
    .allReply_modal-body .ql-toolbar {
        bottom: 70px !important;
        left: 20px !important;
        right: 21px !important
    }
    
.reply_all_active-class{
    top : 90px !important
}
.reply_all_inactive-class{
    top : 5px !important

}
}
