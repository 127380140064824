.company_logo img {
  width: 84px;
}
.company_logo {
  align-items: center;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
}
.company_logo h2 {
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 700;
}
/* a.Links-Invoice {
    color: green;
    display: none;
} */

.modal-body-p {
  padding: 0px 50px;
}
.one-row-design {
  border-left: 2px solid #000;
  padding: 12px 18px;
}

.one-row-design h2 {
  font-weight: 700;
  font-size: 22px;
}
.one-row-design span {
  margin-left: 6px;
  font-weight: 700;
  line-height: 32px;
  font-size: 16px;
}
.date-issue-ff,
.input-type-cl {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-end;
}
.edit-file-design input {
  font-size: 18px;
  font-weight: 400;
  width: -webkit-fill-available;
  height: auto;
  padding: 0px;
  border: none;
}
h3.company-text {
  min-width: 154px;
}
.edit-file-design {
  flex-wrap: nowrap;
  margin-top: 5px;
}
.edit-file-design.large h1 {
  font-weight: 700;
  font-size: 22px;
  min-width: 225px;
}
.edit-file-design.large input {
  font-size: 22px;
  font-weight: 400;
}
.edit-file-design * {
  font-size: 18px;
  background: #fff !important;
}
.edit-file-design.large {
  margin-top: 22px;
}
.Table-1 table {
  width: 100%;
  border-collapse: collapse;
}
.Table-1 table,
.Table-1 td,
.Table-1 th {
  border: 1px solid black;
  padding: 6px;
  vertical-align: top;
}
.Table-1 th {
  color: #fff;
  background: black;
}
.Table-1 {
  padding-top: 30px;
  padding-bottom: 30px;
}
h2.heading-w-border {
  font-weight: 700;
  font-size: 22px;
  border-left: 2px solid #000;
  padding: 12px 18px;
}
.inner-text {
  padding: 0px 18px;
}
.inner-text p {
  margin-bottom: 4px;
  font-size: 16px;
}
.margin-top-last {
  padding-top: 30px;
}
.margin-top-last h2.heading-w-border {
  padding: 4px 18px;
}
.edit-file-design.large input {
  font-size: 22px;
  font-weight: 400;
  height: auto;
}
.edit-file-design input:focus {
  border-color: unset;
  box-shadow: none;
}
td.edit-file-design.link input {
  font-weight: 400;
  font-size: 16px;
  width: 100%;
}
td.edit-file-design.link textarea {
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  color: rgb(26, 32, 44);
  font-weight: 400;
  padding: 5px 0;
  resize: none;
}
.input-type-cl input {
  margin-top: 4px;
  margin-left: 10px;
  max-width: 200px;
}
h2.date-title-lc {
  width: 100%;
}
.one-row-design {
  display: flex;
  width: 50%;
}
h2.date-title-lc.min-w-d {
  min-width: 145px;
}
h1.titlecm-tc {
  text-align: center;
  width: 100%;
  max-width: 450px;
  font-weight: 500;
  margin-top: -41px;
  font-size: 25px;
}
.html2canvas .edit-file-design input {
  margin-top: 4px;
}
.html2canvas .input-type-cl {
  margin-top: 6px;
  padding-left: 6px;
}
.html2canvas .edit-file-design.large input {
  margin-top: 10px;
}
.html2canvas .date-title-lc input {
  margin-top: 4px !important;
}
.html2canvas > input {
  /* margin-top: 100 px; */
}
.Addresses {
  min-width: 530px;
}
td.edit-file-design.link input {
  height: 40px;
}
b.bolds-p {
  width: 145px;
}
td.payments.edit-file-design textarea {
  width: 116px;
}
td.payments.edit-file-design {
  display: flex;
  border: 0;
  line-height: 30px !important;
}
.spinerr {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.spinerr div {
  color: #333;
}
.menu-full {
  /* max-width: 233px;
  min-width: 233px; */
}
.menu-collaps {
}
a.anchers {
  color: steelblue;
}
.span-bold {
  font-weight: 700;
}
.Links-Invoice {
  color: steelblue;
}
.html2canvas {
}
td.payments.edit-file-design {
  flex-wrap: wrap;
}
textarea#payment_terms_input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.inner-text p a {
  color: #000;
}
.Table-1 td div span {
  /* margin: 0 !important;
    padding: 0 !important; */
}
.Table-1 td div {
  text-align: left;
  font-size: 16px;
}
body .Table-1 td div a {
  line-height: 16px !important;
}
.cust_name {
  font-weight: 400 !important;
}
body .edit-file-design label.cust_name {
  font-size: 22px !important;
}
.edit-file-design {
  align-items: center;
}
.edit-file-design input {
  border: none;
  height: auto;
  box-shadow: none !important;
}
@media only screen and (max-width: 767px) {
  body .Addresses {
    min-width: inherit;
  }

  body .edit-file-design.large h1 {
    min-width: inherit;
    white-space: nowrap !important;
    font-size: 10px !important;
  }
  body .edit-file-design.large input,
  body .edit-file-design input {
    margin-top: 8px !important;
  }
  body h3.company-text {
    white-space: nowrap;
  }
  body h3.company-text {
    min-width: inherit;
  }
  body h2.heading-w-border {
    border: none !important;
  }
  hr {
    display: none !important;
  }
  .campaign-regenerate hr {
    display: block !important;
  }
  body .edit-file-design label.cust_name {
    font-size: 12px !important;
  }
  .Table-1 td div {
    /* margin: 0; */
    line-height: 20px;
    font-size: 14px;
    text-align: left;
    /* padding: 0 !important; */
  }
  .Table-1 td div a {
    font-size: 14px;
  }
  /* .Table-1 td div span {
        margin: 0 !important;
        padding: 0 !important;
    } */
  /* h2.heading-w-border {
        border: none !important;
    }
    .inner-text,h2.heading-w-border {
        padding: 0 !important;
    } */
  .edit-file-design input {
    height: 30px !important;
  }
  .mobile-width {
    overflow: hidden !important;
  }
  .one-row-design .flex-invoice-main {
    align-items: center !important;
  }
  /* .one-row-design .flex-invoice-main input {
        max-width: 100px !important;
    } */
  .Table-1 b.bolds-p {
    font-size: 10px !important;
  }
  .invoice-tab {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  .one-row-design {
    padding: 0px 3px !important;
    line-height: 1 !important;
    margin: 0 !important;
  }
  .edit-file-design.large {
    margin-top: 10px !important;
  }
  .Table-1 table {
    table-layout: fixed !important;
  }
  textarea#payment_terms_input {
    height: auto !important;
    font-size: 10px !important;
    padding: 0 !important;
  }
  td.edit-file-design.link input {
    height: 30px !important;
  }
  .one-row-design {
    border: none;
  }
  .one-row-design .flex-invoice-main {
    width: fit-content !important;
  }
  .Table-1 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  h2.heading-w-border {
    font-size: 12px !important;
    border-left: 2px solid #000 !important;
    padding: 0px 15px !important;
    line-height: 18px !important;
  }
  .edit-file-design * {
    font-size: 10px !important;
    background: #fff !important;
  }
  .edit-file-design input {
    font-size: 10px !important;
    margin: 0 0 0 5px !important;
  }
  .Table-1 td,
  .Table-1 th {
    font-size: 10px !important;
  }
  .custom_pdf_popup .chakra-modal__body {
    padding: 10px !important;
  }
  .company_logo div {
    display: flex;
    align-items: center;
    gap: 10px !important;
    margin: 0 !important;
  }
  .inner-text p {
    margin-bottom: 0px !important;
    font-size: 12px !important;
    line-height: 26px !important;
  }
  .company_logo {
    margin: 0 0 -4px !important;
    padding-bottom: 0 !important;
  }
  .edit-file-design.large h1 {
    font-size: 12px !important;
  }
  .inner-text {
    height: 260px;
  }
  .company_logo img {
    width: 84px !important;
  }
  .company_logo h2 {
    font-size: 16px !important;
  }
  .custom_pdf_popup {
    zoom: 67%;
  }
  .custom_pdf_popup .titlecm-tc {
    margin: 0 !important;
    font-size: 12px !important;
  }
  .custom_pdf_popup .chakra-modal__body .company_logo {
    padding-right: 30px;
  }
  .edit-file-design input {
    font-size: 14px;
  }
  h2.date-title-lc.min-w-d {
    min-width: inherit;
  }
  .custom_pdf_popup .one-row-design h2 {
    font-size: 18px;
  }
  .custom_pdf_popup .chakra-modal__body {
    max-height: 150vh;
  }
  .custom_pdf_popup .chakra-modal__body .company_logo img {
    max-width: 100% !important;
    width: 57px !important;
    min-width: 57px !important;
  }
  .menu_style {
    height: auto !important;
  }
  /* .css-1gzszjf {
        padding: 10px 0px;
    } */
  .lablel_desc {
    text-decoration: none; /* Ensure there's no underline */
    color: inherit; /* Inherit color to prevent blue text */
  }
  .one-row-design {
    padding: 4px 18px;
  }
  .space-row {
    gap: 12px !important;
  }
  .inner-text p,
  .inner-text p a,
  .inner-text p,
  .inner-text p s {
    text-decoration: none !important;
    border: none !important;
  }
  .one-row-design span {
    margin-left: 0;
  }
  span.lablel_desc,
  span.lablel_desc s {
    text-decoration: none !important;
    border: none !important;
  }
  /* .company_logo h1 {  
        font-size: 15px;
    } */

  button.chakra-modal__close-btn.css-1ik4h6n {
    right: 3px !important;
  }
  .modal-body-p {
    padding: 0px 40px;
  }
  .space-row {
    flex-wrap: nowrap;
  }
  .mobile-width {
    width: 100%;
  }
  .flex-invoice-main,
  .date-issue-ff {
    flex-wrap: nowrap;
  }
  .menu-full {
    max-width: 100%;
    min-width: 100%;
  }
  .css-cspn6r {
    padding: 100px !important;
  }
  .css-143ixz9 {
    padding: 100px !important;
  }
  .css-n2r6ja {
    text-align: center;
  }
  .css-6psgwk {
    height: calc(65vh - 60px) !important;
  }
  body .Table-1 td div span {
    padding: 0 !important;
  }
  body .one-row-design.edit-file-design input#date {
    margin-top: 0 !important;
  }
  body .one-row-design.edit-file-design input {
    margin-top: 0 !important;
  }

  body .Table-1 {
    width: 100% !important;
  }
  body .Table-1 td div span,
  body .Table-1 td div a {
    display: inline-block !important;
  }
  body .edit-file-design {
    flex-wrap: nowrap;
    margin-top: 0;
  }
  body .Table-1 th {
    padding-top: 0 !important;
  }
}
