
  .anchers{
    /* text-decoration: underline; */
    color: rgba(3, 59, 47, 0.979);

   
  }
  .anchers:hover {
    color: rgba(3, 59, 47, 1); /* Darker color on hover */
    text-decoration: underline;
    cursor: pointer;
  }

  .arrow-lefts {
    margin-left: -14px;
    display: grid;
    height: 38px;
    width: 38px;
    place-items: center;
    padding: 0;
}
.arrow-lefts {
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-header div {
    align-items: center;
    gap: 5px;
}
.css-a5mhaz {
  padding: 0 0 0 0 !important;
}